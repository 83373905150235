.label {
  font-weight: 600;
  font-size: 14px;
}

.textarea {
  display: flex;
  max-width: 100%;
  min-width: 100%;
  width: 100%;
  align-items: center;
  background-color: #f5f5f5;
  padding: 0.4375rem;
  border-radius: 0.25rem;
  margin: 0;
  color: var(--black);
  border: none;
  outline: none;
}

.readOnly {
  color: var(--input-text-disabled);
}

.readOnly:hover {
  cursor: not-allowed;
}

/* textarea:not(.hasLegend) {
  margin-bottom: .625rem;
} */

.legend {
  margin-top: 0.5rem;
  font-size: 0.75rem;
  font-style: italic;
}

textarea.invalid {
  padding: 0.375rem;
  border: 0.0625rem solid var(--red);
  box-sizing: border-box;
}

textarea.valid {
  padding: 0.375rem;
  border: 0.0625rem solid var(--green);
  box-sizing: border-box;
}

.message {
  margin-top: 0.25rem;
  margin-bottom: 0.25rem;
  font-size: 0.875rem;
}

.message.invalid {
  color: var(--red);
}

.message.valid {
  color: var(--green);
}

.backgroundWhite {
  background-color: white;
}
