.table {
  width: 100%;
  /* box-sizing: border-box; */
  border-collapse: collapse;
}

.table tbody tr {
  border-top: 2px solid var(--border-color);
}

.table tbody tr td {
  padding-top: 15px;
  padding-bottom: 15px;
  color: var(--black);
}

.table tr td:not(:first-child) {
  padding-left: 10px;
}

.table tr td:not(:last-child) {
  padding-right: 10px;
}

.table th {
  color: var(--gray);
  font-weight: 700;
}

.table th:not(:first-child) {
  padding-left: 10px;
}

.table th:not(:last-child) {
  padding-right: 10px;
}
