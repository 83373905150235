@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@200;300;400;500;600;700;800;900&family=Titillium+Web:wght@400;500;600;700;800;900&display=swap");

:root {
  --primary-color: #19323c;
  --primary-color-light: #9ab3d7;
  --primary-color-dark: #254b81;

  --secondary-color: #00953b;
  --secondary-color-light: #48be78;
  --secondary-color-dark: #035323;

  --white: #fff;
  --black: #323232;

  --blue: #19323c;
  --blue-lighter: #13262e;
  --blue-darker: #19323c;

  --yellow: #00953b;
  --yellow-lighter: #48be78;
  --yellow-darker: #035323;

  --red: #e74c3c;
  --red-lighter: #e96a5c;
  --red-darker: #cc4335;
  --light-red: #e96a5c;

  --green: #00ce90;
  --green-darker: #00ad79;
  --green-lighter: #00e49f;

  --gray: #575656;
  --gray-darker: #474747;
  --gray-lighter: #666565;

  --light-gray: #f5f5f5;

  --background: #f2f4f9;
  --button-hover: #bbbcc0;
  --drop-shadow: drop-shadow(0px 15px 30px rgba(0, 0, 0, 0.05));
  --border-color: #f5f5f5;
  --border: 1px solid var(--border-color);
  --unselected-icon: #bdbdbd;
  --dot-border: #e0e0e0;

  --secondary-text: #848484;

  --input-icon: #b4b4b4;
  --input-placeholder: #848484;
  --input-text-disabled: #c0c0c0;

  --progress-bar-background: var(--background);
  --progress-bar-complete: var(--blue);

  --important-number-text: #5b5b5b;
}

html {
  color: var(--black);
  font-size: 16px;
}

html * {
  font-family: "Montserrat", sans-serif;
  box-sizing: border-box;
}

body {
  background-color: var(--background);
  margin: 0;
}

::-webkit-scrollbar {
  width: 0.4375rem;
  height: 0.4375rem;
}

::-webkit-scrollbar-thumb {
  background: #5c5c5c;
  border-radius: 0.625rem;
}

::-webkit-scrollbar-track {
  background: var(--background);
}
