.filePreview {
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: contain;
  width: 200px;
  height: 200px;
  position: relative;
  margin: 0.25rem;
}

.videoPreview {
  height: 200px;
  width: 200px;
  position: relative;
}

.videoPreview video {
  height: 200px;
  width: 200px;
}

.filePreviewContainer {
  margin-top: 1rem;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  /* justify-content: space-around; */
}

.filePreviewContainer.empty {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 5rem;
  background-color: var(--background);
  color: var(--secondary-text);
}

.filePreviewContainer.empty::after {
  content: "Prévisualisation des fichiers";
}

.trashDeleteMedia {
  position: absolute;
  color: var(--red);
  top: 1rem;
  right: 1rem;
  cursor: pointer;
}

.onHoverMoving:hover {
  filter: blur(1px);
}
