.button {
  border: none;
  font-weight: 600;
  color: var(--white);
  cursor: pointer;
  /* display: inline-flex; */
  align-items: center;
  transition-duration: 150ms;
  text-align: center;
}

.fullWidth {
  width: 100%;
}

.large {
  padding: 0.8125rem 2rem;
}

.small {
  padding: 0.5625rem 1rem;
}

.xs {
  padding: 0.3571rem 0.7143rem;
}

.round {
  border-radius: 2.5rem;
}

.square {
  border-radius: 0.25rem !important;
}

.blue {
  background-color: var(--blue);
}

.yellow {
  color: var(--black);
  background-color: var(--yellow);
}

.red {
  background-color: var(--red);
}

.gray {
  background-color: var(--gray);
}

.green {
  background-color: var(--green);
}

.white {
  background-color: var(--white);
  border: 2px solid var(--light-gray);
  border-radius: 10px;
}

.blue:hover {
  background-color: var(--blue-darker);
}

.yellow:hover {
  background-color: var(--yellow-darker);
}

.red:hover {
  background-color: var(--red-darker);
}

.gray:hover {
  background-color: var(--gray-darker);
}

.green:hover {
  background-color: var(--green-darker);
}

.white:hover {
  background-color: var(--light-gray);
  filter: contrast(80%) brightness(105%);
}

.blue:disabled {
  background-color: var(--blue-lighter);
}

.yellow:disabled {
  background-color: var(--yellow-lighter);
}

.red:disabled {
  background-color: var(--red-lighter);
}

.gray:disabled {
  background-color: var(--gray-lighter);
}

.green:disabled {
  background-color: var(--green-lighter);
}

.white:disabled {
  background-color: var(--gray-darker);
}

.button:disabled {
  cursor: not-allowed;
}

.loader {
  animation: rotate 1s infinite linear;
  margin-right: 0.625rem;
}

/* .large .loader {
    width: 1rem;
    height: 1rem;
}

.small .loader {
    width: 0.75rem;
    height: 0.75rem;
} */

@keyframes rotate {
  0% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(360deg);
  }
}
