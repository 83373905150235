.card {
  padding: 0.9375rem 1.5625rem 1.5625rem;
  background: var(--white);
  border-radius: 0.625rem;
  filter: var(--drop-shadow);
}

.title {
  font-weight: 700;
}

.separator {
  height: 2px;
  width: 100%;
  background-color: var(--border-color);
  margin-top: 0.75rem;
  margin-bottom: 0.75rem;
}

.titleContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
}