:root {
  --carouSize: 80px;
}

.productCover {
  width: var(--carouSize);
  height: var(--carouSize);
  background-repeat: no-repeat;
  background-position: center center;
  background-size: contain;
}

.productCoverMultiple {
  width: var(--carouSize);
  height: var(--carouSize);
  background-repeat: no-repeat;
  background-position: center center;
  background-size: contain;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  position: relative;
}

.productCoverMultiple video {
  width: 100%;
  height: 100%;
  background-color: black;
}

.carretContainer {
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-top: 10px;
  padding-bottom: 10px;
  position: absolute;
  opacity: 0;
  width: 50%;
  height: 100%;
  z-index: 0;
}

.carretContainer.left {
  left: 0;
  border-radius: 0 4px 4px 0;
}

.carretContainer.right {
  right: 0;
  border-radius: 4px 0 0 4px;
}

.carretContainer:hover {
  cursor: pointer;
}

.dotContainer {
  display: flex;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 0 0 4px 4px;
  z-index: 10;
}

.dot {
  height: 8px;
  margin: 2px;
}

.dot:hover {
  cursor: pointer;
}

.faCaretLeft {
  font-size: 0.75rem !important;
  width: 0.75rem !important;
  color: white;
}

.faCaretRight {
  font-size: 0.75rem !important;
  width: 0.75rem !important;
  color: white;
}
