.label {
  font-weight: 600;
  font-size: .875rem;
}

.inputContainer {
  display: flex;
  align-items: stretch;
  border-radius: .25rem;
  position: relative;
}

.inputContainer input {
  font-size: .875rem;
  outline: none;
}

.inverted {
  flex-direction: row-reverse;
}

.inputContainer:not(.hasLegend) {
  margin-bottom: .625rem;
}

.legend {
  margin-top: .5rem;
  font-size: .75rem;
  font-style: italic;
}

.inputContainer input {
  background-color: transparent;
  border: none;
  flex: 1;
  width: 100%;
}

.inputContainer input::placeholder {
  color: var(--input-placeholder);
}

.inputContainer input:read-only {
  color: var(--input-text-disabled);
}

.inputContainer input:read-only:hover {
  cursor: not-allowed;
}

div.invalid {
  outline: .0625rem solid var(--red);
  box-sizing: border-box;
}

div.valid {
  outline: .0625rem solid var(--green);
  box-sizing: border-box;
}

.message {
  margin-top: .25rem;
  margin-bottom: .25rem;
  font-size: .875rem;
}

.message.invalid {
  color: var(--red);
}

.message.valid {
  color: var(--green);
}

.searchIcon {
  width: 1rem;
  height: 1rem;
  color: var(--input-icon);
}

.clearIcon {
  width: 1rem;
  height: 1rem;
  color: var(--input-icon);
  cursor: pointer;
  transition-duration: 150ms;
}

.clearIcon:not(:first-child) {
  margin-right: .25rem
}

.clearIcon:hover {
  color: var(--black);
}

.buttonChooseFile {
  background-color: var(--yellow);
  /* left: 0; */
  /* height: 100%; */
  /* position: absolute; */
  border-top-left-radius: .25rem;
  border-bottom-left-radius: .25rem;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  padding: .4375rem .75rem;
  font-weight: 600;
  font-size: inherit;

}

.filename {
  flex: 1;
  background-color: #f5f5f5;
  padding: .4375rem .75rem;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-top-right-radius: .25rem;
  border-bottom-right-radius: .25rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.inverted .buttonChooseFile {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-top-right-radius: .25rem;
  border-bottom-right-radius: .25rem;
}

.inverted .filename {
  border-top-left-radius: .25rem;
  border-bottom-left-radius: .25rem;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.backgroundWhite {
  background-color: white;
}