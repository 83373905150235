.container {
  margin-left: 0;
  margin-bottom: 0;
  font-weight: 600;
}

.number {
  color: var(--primary-color);
}

.text {
  color: var(--important-number-text);
}