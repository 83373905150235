.iconsColumn {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-evenly;
  width: 100%;
}

.actionIcon {
  font-size: 1.25rem;
  color: var(--black);
  transition: color 0.2s;
}

.actionIconContainer {
  padding: 1rem;
  margin-right: 0.625rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.actionIconContainer:hover {
  cursor: pointer;
  color: var(--gray);
  box-shadow: 0 0 0 2px var(--button-hover);
  border-radius: 0.5rem;
}

.actionIconContainer:last-of-type {
  margin-right: 0px;
}

.container {
  display: flex;
  margin: auto;
  max-width: 93.75rem;
}

.card {
  margin: 2rem;
  flex: 1;
}

.cardHeader {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 1.25rem;
}

.cardHeaderInput {
  margin-bottom: 0 !important;
  width: 15.625rem !important;
}

.categoriesContainer {
  display: flex;
  flex-wrap: wrap;
  margin-top: 0.9286rem;
  margin-left: -0.5625rem;
  /* justify-content: space-between; */
}

.categoriesContainerEmpty {
  background-color: var(--background);
  color: var(--secondary-text);
  width: 100%;
  height: 15rem;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  cursor: pointer;
}

.emptyIcon {
  width: 5rem;
  height: 5rem;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}

.categoryContainer {
  width: 23%;
  margin: 0.5625rem;
  position: relative;
  border: 1px solid var(--border-color);
  border-radius: 0.3125rem;
  min-width: 14rem;
}

.categoryFirstCol {
  display: flex;
  align-items: center;
}

.categoryImage {
  width: 7.5714rem;
  height: 4.3571rem;
  display: inline-block;
  border-top-left-radius: 0.3125rem;
  border-top-right-radius: 0.3125rem;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  position: relative;

  text-align: left;
}

.categoryImage::before {
  content: "";
  display: block;
  padding-top: 100%; /* initial ratio of 1:1*/
}

.categoryContent {
  background-color: white;
  width: 100%;
  padding: 0.625rem 0.9375rem 0.8125rem 0.9375rem;
  border-bottom-left-radius: 0.3125rem;
  border-bottom-right-radius: 0.3125rem;
}

.categoryName {
  font-weight: 500;
  margin-left: 1rem;
}

.categoryDescription {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}

.categoryPreview {
  padding: 0.3571rem 0.7143rem;
}

.categorysContainerHeader {
  font-weight: 700;
  margin: 1rem 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.categoryFooter {
  margin-top: 1.5rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.categoryThreeDots {
  cursor: pointer;
  margin-right: -0.25rem;
  padding: 0 0.25rem;
}

.popover {
  top: 100%;
  margin-top: -1rem;
  left: 0px;
}

.productsContainer {
  display: flex;
  flex-wrap: wrap;
  margin-top: 0.9286rem;
  margin-left: -0.5625rem;
}

.modalDeleteButtons {
  display: flex;
}

.modalDeleteButtons > *:first-child {
  margin-right: 0.625rem;
}

.modalDeleteButtons > *:last-child {
  margin-left: 0.625rem;
}

.table tbody tr td {
  padding-top: 2px;
  padding-bottom: 2px;
  box-sizing: content-box;
}
