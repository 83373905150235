.container {
  display: flex;
  margin: auto;
  max-width: 93.75rem;
}

.card {
  margin: 2rem;
  flex: 1;
}

.cardHeader {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 1.25rem;
}

.cardHeaderInput {
  margin-bottom: 0 !important;
  width: 15.625rem !important;
}

.productsContainer {
  display: flex;
  flex-wrap: wrap;
  margin-top: 0.9286rem;
  margin-left: -0.5625rem;
  /* justify-content: space-between; */
}

.productContainer {
  /* width: 23%; */
  width: 278px;
  height: 28rem;
  margin: 0.5625rem;
  position: relative;
  border: 1px solid var(--border-color);
  border-radius: 0.3125rem;
  min-width: 14rem;
}

.productImage {
  width: 100%;
  border-top-left-radius: 0.3125rem;
  border-top-right-radius: 0.3125rem;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  cursor: pointer;
  position: relative;
}

.productImage::before {
  content: "";
  display: block;
  padding-top: 100%; /* initial ratio of 1:1*/
}

.productContent {
  background-color: white;
  width: 100%;
  padding: 2rem 0.9375rem 0.8125rem 0.9375rem;
  border-bottom-left-radius: 0.3125rem;
  border-bottom-right-radius: 0.3125rem;
}

.productContainerEmpty {
  background-color: var(--background);
  color: var(--secondary-text);
  width: 100%;
  height: 15rem;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  cursor: pointer;
}

.emptyIcon {
  width: 5rem;
  height: 5rem;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}

.productName {
  font-weight: 700;
}

.pricesContent {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 0.2rem;
  margin-bottom: 0.2rem;
  padding-bottom: 0.2rem;
}

.priceTtc {
  /*margin-left: 0.7143rem;*/
}

.priceHt {
  margin-left: 0.7143rem;
}

.productDescription {
  overflow: hidden;
  overflow-wrap: anywhere;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  line-clamp: 3;
  -webkit-box-orient: vertical;
}

.productPreview {
  position: relative;
  padding: 0.3571rem 0.7143rem;
}

.productsContainerHeader {
  font-weight: 700;
  margin: 1rem 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.productFooter {
  margin-top: 1.5rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: absolute;
  width: 100%;
  bottom: 0;
  left: 0;
  padding: 0 0.9375rem 0.8125rem 0.9375rem;
}

.productThreeDots {
  position: relative;
  cursor: pointer;
  /* margin-right: -0.25rem; */
  padding: 0 0.25rem;
}

.popover {
  top: 100%;
  margin-top: -1rem;
  left: 0px;
}
.modalDeleteButtons {
  display: flex;
}

.modalDeleteButtons > *:first-child {
  margin-right: 0.625rem;
}

.modalDeleteButtons > *:last-child {
  margin-left: 0.625rem;
}
