.container {
    height: .3125rem;
    width: 100%;
    background-color: var(--progress-bar-background);
    border-radius: 1.25rem;
}

.progress {
    height: .3125rem;
    width: 0px;
    background-color: var(--progress-bar-complete);
    border-radius: 1.25rem;
}