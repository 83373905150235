:root {
  --drop-shadow: drop-shadow(0px 15px 30px rgba(0, 0, 0, 0.05));
  --medium-drop-shadow: drop-shadow(0px 10px 10px rgba(0, 0, 0, 0.125));
}

html {
  font-size: 14px;
}

.shepherd-content {
  background-color: #fff;
  border: 1px solid var(--border-color);
  border-radius: 10px;
  filter: var(--hard-drop-shadow);
}

.shepherd-header {
  background-color: var(--primary-color);
  color: #fff;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  padding: 15px;
}

.shepherd-title {
  margin-bottom: 0px;
  margin-top: 0;
  font-size: 1rem;
  font-weight: 600;
  display: flex;
  justify-content: space-between;
}

.shepherd-text {
  padding: 15px;
  font-weight: 500;
}

.shepherd-footer {
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  border: 1px solid var(--border-color);
  display: flex;
}

.shepherd-button {
  outline: none;
  border: none;
  background-color: #fff;
  color: var(--black);
  font-weight: 700;
  flex: 1;
  padding: 1rem 0px;
}

.shepherd-button:disabled {
  opacity: 0.5;
}

.shepherd-cancel-icon {
  background-color: transparent;
  background-image: url("../public/xmark-solid.svg");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  width: 32px;
  height: 32px;
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
  border: none;
  font-size: 0;
  color: white;
}

.shepherd-button-skip {
  border-bottom-left-radius: 10px;
}

.shepherd-button-back {
  border-left: 1px solid var(--border-color);
  border-right: 1px solid var(--border-color);
}

.shepherd-button-next {
  border-bottom-right-radius: 10px;
  color: var(--primary-color);
}

.shepherd-button:hover:enabled {
  background-color: var(--primary-color);
  color: #fff;
  transition: background-color 200ms, color 200ms;
}

.shepherd-element {
  z-index: 9999;
}

.shepherd-element .shepherd-arrow {
  border-width: 0;
  height: 20px;
  width: 18px;
  z-index: 10;
  filter: var(--hard-drop-shadow);
}

.shepherd-arrow::before {
  display: none;
}

.onboarding-arrow-blue .shepherd-arrow::after {
  content: url("../public/img/triangle-blue.svg") !important;
}

.onboarding-arrow-yellow .shepherd-arrow::after {
  content: url("../public/img/triangle-yellow.svg") !important;
}

.shepherd-element .shepherd-arrow::after {
  content: url("../public/img/triangle-white.svg");
  display: inline-block;
  position: absolute;
}

.shepherd-element[data-popper-placement^="top"] .shepherd-arrow,
.shepherd-element.shepherd-pinned-top .shepherd-arrow {
  bottom: -18px;
  left: 12px;
}

.shepherd-element[data-popper-placement^="top"] .shepherd-arrow:after,
.shepherd-element.shepherd-pinned-top .shepherd-arrow:after {
  transform: rotate(270deg);
}

.shepherd-element[data-popper-placement^="bottom"] .shepherd-arrow {
  top: -22px;
}

.shepherd-element[data-popper-placement^="bottom"] .shepherd-arrow:after {
  transform: rotate(90deg);
}

.shepherd-element[data-popper-placement^="left"] .shepherd-arrow,
.shepherd-element.shepherd-pinned-left .shepherd-arrow {
  right: -22px;
}

.shepherd-element[data-popper-placement^="left"] .shepherd-arrow:after,
.shepherd-element.shepherd-pinned-left .shepherd-arrow:after {
  transform: rotate(180deg);
}

.shepherd-element[data-popper-placement^="right"] .shepherd-arrow,
.shepherd-element.shepherd-pinned-right .shepherd-arrow {
  left: -18px;
}

.shepherd-element[data-popper-placement^="end"] .shepherd-arrow {
  top: -29px;
}

.important-text {
  color: var(--primary-color);
  font-weight: 600;
}

.important-text-yellow {
  color: var(--secondary-color);
  font-weight: 600;
}

.important-text-green {
  color: var(--green);
  font-weight: 600;
}

.important-text-red {
  color: var(--red);
  font-weight: 600;
}

.important-text-black {
  color: var(--black);
  font-weight: 600;
}

.shepherd-modal-overlay-container.shepherd-modal-is-visible {
  height: 100vh;
  opacity: 0.5;
  transition: all 0.3s ease-out, height 0s 0s, opacity 0.3s 0s;
}

.shepherd-modal-overlay-container {
  height: 0;
  left: 0;
  opacity: 0;
  overflow: hidden;
  pointer-events: none;
  position: fixed;
  top: 0;
  transition: all 0.3s ease-out, height 0ms 0.3s, opacity 0.3s 0ms;
  width: 100vw;
  z-index: 9997;
}

.shepherd-modal-overlay-container.shepherd-modal-is-visible path {
  pointer-events: all;
}

.shepherd-target-click-disabled {
  pointer-events: none;
}

/* .ql-editor {
  min-height: 10rem;
} */

.ql-container {
  min-height: 10rem;
}
