.label {
  font-weight: 600;
  font-size: 1rem;
}

.inputContainer {
  display: flex;
  align-items: center;
  background-color: #f5f5f5;
  padding: 0.4375rem;
  border-radius: 0.25rem;
  position: relative;
  /* width: 100%; */
}

.inputContainer input {
  font-size: 1rem;
  outline: none;
}

.inverted {
  flex-direction: row-reverse;
}

.inputContainer:not(.hasLegend) {
  margin-bottom: 0.625rem;
}

.legend {
  margin-top: 0.5rem;
  font-size: 0.75rem;
  font-style: italic;
}

.inputContainer input {
  background-color: transparent;
  border: none;
  flex: 1;
  width: 100%;
}

.inputContainer input::placeholder {
  color: var(--input-placeholder);
}

.inputContainer input:read-only {
  color: var(--input-text-disabled);
}

.inputContainer input:read-only:hover {
  cursor: not-allowed;
}

div.invalid {
  padding: 0.375rem;
  border: 0.0625rem solid var(--red);
  box-sizing: border-box;
}

div.valid {
  padding: 0.375rem;
  border: 0.0625rem solid var(--green);
  box-sizing: border-box;
}

.message {
  margin-top: 0.25rem;
  margin-bottom: 0.25rem;
  font-size: 1rem;
}

.message.invalid {
  color: var(--red);
}

.message.valid {
  color: var(--green);
}

.backgroundWhite {
  background-color: white;
}

.searchIcon {
  width: 1rem;
  height: 1rem;
  color: var(--input-icon);
}

.clearIcon {
  width: 1rem;
  height: 1rem;
  color: var(--input-icon);
  cursor: pointer;
  transition-duration: 150ms;
}

.clearIcon:not(:first-child) {
  margin-right: 0.25rem;
}

.clearIcon:hover {
  color: var(--black);
}

.isInvalid {
  border: 1px solid var(--red);
  box-sizing: border-box;
}

.isInvalidText {
  color: var(--red);
  font-size: 14px;
  position: absolute;
  top: -2.2857rem;
}

@media only screen and (max-width: 425px) {
  .isInvalidText {
    font-size: 10px;
    top: -1.7857rem;
    left: 0;
  }
}

@media only screen and (max-width: 375px) {
  .isInvalidText {
    font-size: 9px;
    top: -1.5857rem;
  }
}
