.container {
  display: flex;
  /* margin: auto; */
  /* max-width: 93.75rem; */
  min-height: 57.5vh;
}

.card {
  margin: 2rem;
  flex: 1;
}

.label {
  font-weight: 600;
  font-size: 1rem;
}

.filePreview {
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: contain;
  width: 200px;
  height: 200px;
  position: relative;
}

.videoPreview {
  height: 200px;
  width: 200px;
  position: relative;
}

.videoPreview video {
  height: 200px;
  width: 200px;
}

.filePreviewContainer {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.filePreviewContainer.empty {
  margin-top: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 5rem;
  background-color: var(--background);
  color: var(--secondary-text);
}

.filePreviewContainer.empty::after {
  content: "Prévisualisation des fichiers";
}

.trashDeleteMedia {
  position: absolute;
  color: var(--red);
  top: 1rem;
  right: 1rem;
  cursor: pointer;
}

.subCatContainer {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin-top: 2rem;
  flex: 1;
}

.suCatFormTitle {
  font-weight: 600;
  margin-bottom: 1rem;
}

.subCatFormik {
  display: flex;
  flex-flow: wrap;
  width: 100%;
}

.subCatName {
  display: flex;
  flex-direction: column;
  width: 15rem;
}

.subCatElement {
  position: relative;
}

.subCatTrash {
  color: var(--red) !important;
  margin-left: 0.4rem;
}

.subCatPen {
  margin-left: 0.3rem;
}

.subCatButtons {
  cursor: pointer;
  font-size: 1rem;
}

.fontAwesomeIcon {
  color: var(--gray-darker);
}

.fontAwesomeIcon:hover {
  color: var(--gray-lighter);
}

/***/

.confirmIcon {
  color: var(--green);
  font-size: 64px;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.title {
  font-weight: 600;
  text-align: center;
  margin-top: 10px;
  margin-bottom: 10px;
}

.text {
  text-align: center;
  margin-top: 0;
  margin-bottom: 0;
}

.chooseFileButton {
  width: 100%;
  margin-right: 1rem;
}
