.card {
  border: 1px solid var(--border-color);
}

.nameInputContainer {
  display: flex;
}

.titleContainer {
  display: flex;
  flex-direction: column;
}

.title {
  font-weight: 600;
}

.title,
.subtitle {
  margin-bottom: 1.5rem;
}

.inputsContainer {
  display: grid;
  align-items: baseline;

  grid-template-columns: 1fr 3fr;
  grid-template-rows: repeat(4, 1fr) 7.6429rem 1fr;
  grid-gap: 10px;
}

.inputLabel {
  font-weight: 600;
  text-align: right;
}

.messageInput {
  resize: none;
  height: 7.1429rem;
}

@media only screen and (max-width: 425px) {
  .inputsContainer {
    display: flex;
    align-items: stretch;
    flex-direction: column;
  }

  .inputLabel {
    font-weight: 600;
    text-align: left;
    margin-bottom: 0.25rem;
  }

  .card {
    overflow: visible;
    height: fit-content;
  }
}
