.productCover {
  width: 100%;
  height: 300px;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: contain;
}

.productCoverMultiple {
  width: 100%;
  height: 300px;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: contain;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  position: relative;
}

.productCoverMultiple video {
  width: 100%;
  height: 100%;
  background-color: black;
}

.carretContainer {
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-top: 30px;
  padding-bottom: 30px;
  position: absolute;
}

.carretContainer.left {
  left: 0;
  border-radius: 0 4px 4px 0;
}

.carretContainer.right {
  right: 0;
  border-radius: 4px 0 0 4px;
}

.carretContainer:hover {
  cursor: pointer;
}

.dotContainer {
  display: flex;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 0 0 4px 4px;
}

.dot {
  height: 15px;
  margin: 5px;
}

.current {
  color: #f4d03f;
}

.dot:hover {
  cursor: pointer;
}

.faCaretLeft {
  font-size: 1.4286rem !important;
  width: 1.4286rem !important;
  color: white;
}

.faCaretRight {
  font-size: 1.4286rem !important;
  width: 1.4286rem !important;
  color: white;
}

.videoIcon {
  transform-origin: center;
  position: absolute;
  left: 50%;
  top: 50%;
  font-size: 2rem;
}
