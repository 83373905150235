.container {
  display: flex;
  margin: auto;
  max-width: 93.75rem;
}

.card {
  margin: 2rem;
  flex: 1;
}

.cardHeader {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 1.25rem;
}

.cardHeaderInput {
  margin-bottom: 0 !important;
  width: 15.625rem !important;
}

.catalogRow {
  cursor: pointer;
}

.catalogName {
  display: flex;
  align-items: center;
  font-weight: 500;
}

.catalogImage {
  width: 4.5rem;
  height: 4.5rem;
  display: inline-block;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  margin-right: 0.7143rem;
}

.btnAddFolder {
  margin-top: 4.375rem;
}

.catalogContainerEmpty {
  background-color: var(--background);
  color: var(--secondary-text);
  width: 100%;
  height: 15rem;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  cursor: pointer;
}

.emptyIcon {
  width: 5rem;
  height: 5rem;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}

.modalDeleteButtons {
  display: flex;
}

.modalDeleteButtons > *:first-child {
  margin-right: 0.625rem;
}

.modalDeleteButtons > *:last-child {
  margin-left: 0.625rem;
}

.cardTitle {
  display: flex;
  justify-content: space-between;
}

.empty {
  background-color: var(--background);
  text-align: center;
  padding: 6.6875rem 0;
  color: var(--secondary-text);
}

.separator {
  margin-top: 0.625rem;
  margin-bottom: 0.3125rem;
}

.search {
  display: flex;
  align-items: stretch;
  margin: 1.25rem;
  position: relative;
}

.searchSelect {
  min-width: 8rem;
}

.searchInput {
  width: 40%;
  margin-left: 1.25rem;
  margin-bottom: 0 !important;
}

.actionIcon {
  font-size: 1.25rem;
  color: var(--black);
  transition: color 0.2s;
}

.actionIconContainer {
  padding: 1rem;
  margin-right: 0.625rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.actionIconContainer:hover {
  cursor: pointer;
  color: var(--gray);
  box-shadow: 0 0 0 2px var(--button-hover);
  border-radius: 0.5rem;
}

.actionIconContainer:last-of-type {
  margin-right: 0px;
}

.iconsColumn {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-evenly;
  width: 100%;
}
