.react-colorful {
  height: 200px !important;
  width: 100% !important;
}
.react-colorful__saturation {
  margin-left: 125px;
  border-radius: 0 !important;
  /* height: 125px; */
}

.react-colorful__hue {
  /* height: 40px; */
  height: 0.5rem !important;
  border-radius: 1rem !important;
  margin: 1rem !important;
}
