.container {
    display: flex;
    align-items: center;
    font-weight: 600;
    color: var(--blue);
}

.item {
    margin-left: .625rem;
    margin-right: .625rem;
}

.item:first-of-type {
    margin-left: 0;
}

item:last-of-type {
    margin-right: 0;
}

.item:hover {
    cursor: pointer;
    color: var(--black);
}

.separator {
    font-size: .625rem;
    display : inline-block;
    height : 0;
    width : 0;
    border-top : .5625em solid transparent;
    border-bottom : .5625em solid transparent;
    border-left : 1em solid var(--blue);
}

.separator:last-of-type {
    display: none;
}

.active {
    color: var(--black);
}

.active:hover {
    cursor: auto;
}