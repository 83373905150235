.tabContainer {
  display: flex;
  flex-direction: column;
  overflow: auto;
}

.tabItemsContainer {
  margin-left: 1rem;
}

.tabItem {
  background-color: var(--border-color);
  padding: 0.7143rem 1.7857rem;
  font-weight: 600;
  display: inline-block;
  border-radius: 0.7143rem 0.7143rem 0px 0px;
  cursor: pointer;
  transition: background-color 100ms;
}

.tabItem:hover {
  background-color: var(--dot-border);
}

.tabItem.selected {
  background-color: var(--blue);
  color: white;
}

.tabContent {
  border: 1px solid var(--border-color);
  padding: 1rem;
  border-radius: 0.7143rem;
  display: flex;
  flex-direction: column;
  overflow: auto;
}
