.container {
    width: 100%;

    font-weight: 500;
    background-color: white;
    position: absolute;
    top: 100%;
    display: flex;
    flex-direction: column;
    z-index: 1;
    transform: translateY(.5rem);
    color: var(--black);
}

.container div {
    width: 100%;
    padding: .5rem .625rem .4375rem .625rem;
    border: .0625rem solid #F2F4F9;
}

.container div:first-child {
    border-top-left-radius: .3125rem;
    border-top-right-radius: .3125rem;
}

.container div:last-child {
    border-bottom-left-radius: .3125rem;
    border-bottom-right-radius: .3125rem;
}

.container div:hover {
    color: var(--blue);
    cursor: pointer;
}