.modal {
  padding: 0.9375rem 1.5625rem 1.5625rem;
  background: var(--white);
  border-radius: 0.625rem;
  filter: var(--drop-shadow);
  outline: none;
  width: 28.125rem;
  max-height: 80%;
  overflow-y: auto;
}

.body {
  display: flex;
  flex-direction: column;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  /* height: 100vh; */
  width: 100%;
  background-color: rgba(0, 0, 0, 0.3);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 15;
}

.title {
  font-weight: 700;
  text-align: center;
}

.separator {
  height: 2px;
  width: 100%;
  background-color: var(--border-color);
  margin-top: 0.75rem;
  margin-bottom: 0.75rem;
}

.titleContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.titleContainer.titleCenter {
  color: var(--blue);
}

.titleContainer.titleLeft {
  color: var(--black);
}

.titleLeft div:first-of-type {
  display: none;
}

.close {
  width: 1rem;
  height: 1rem;
  cursor: pointer;
  transition-duration: 150ms;
}

.titleCenter .close:hover {
  color: var(--blue-darker);
}

.titleLeft .close:hover {
  color: black;
}

.confirmIcon {
  color: var(--green);
  font-size: 64px;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

/* .title {
  font-weight: 600;
  text-align: center;
  margin-top: 10px;
  margin-bottom: 10px;
} */

.text {
  text-align: center;
  margin-top: 0;
  margin-bottom: 0;
}
