.twoColumns {
  display: flex;
}

.firstColumn:not(.zoom) {
  width: 428px;
}

.firstColumn.zoom {
  width: 642px;
}

.mainImage:not(.zoom) {
  width: 428px;
  height: 340px;
}

.mainImage.zoom {
  width: 642px;
}

.mainImage {
  box-sizing: content-box;
  border: 2px solid var(--border-color);
  cursor: zoom-in;
}

.mainImage img,
.mainImage video {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.smallImage {
  position: relative;
  margin: 0.25rem;
  width: 64px;
  height: 50px;
  margin-right: 0.25rem;
  margin-left: 00.25rem;
  box-sizing: content-box;
  border: 2px solid var(--border-color);
}

.smallImage:hover {
  cursor: pointer;
  /* border: 2px solid var(--blue); */
  filter: var(--hard-drop-shadow);
}

.smallImage img,
.smallImage video {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.empty img {
  object-fit: cover;
}

.smallImage.selected {
  border: 2px solid var(--blue);
}

/* .newImageContainer {
  display: flex;
  flex-direction: column;

  justify-content: space-between;
  align-items: center;
} */

.imageInputContainer {
  align-self: stretch;
}

.newImageButton {
  margin-top: 2rem;
}

.imagesContainer {
  display: flex;
  flex-wrap: wrap;
  margin-top: 1rem;
  justify-content: center;
}

.newMedia {
  display: flex;
  margin: 0.5rem;
  position: relative;
  width: 100%;
  height: 100%;
  font-size: 2.5714rem;
}

.trashDeleteMedia {
  position: absolute;
  color: var(--red);
  top: 0.25rem;
  right: 0.25rem;
  cursor: pointer;
  z-index: 1;
}

.trashDeleteMedia:hover {
  color: var(--red-lighter);
}

.editDescriptionButton {
  font-size: 1rem;
  display: flex;
  align-items: center;
}

.editButton {
  color: var(--gray-darker);
  cursor: pointer;
  z-index: 1;
}

.editButton:hover {
  color: var(--gray-lighter);
}

.cancelButton {
  color: var(--red);
}

.cancelButton:hover {
  color: var(--red-lighter);
}

.name {
  display: flex;
  font-weight: 700;
  font-size: 1.5rem;
  margin-bottom: 1rem;
  justify-content: space-between;
}

.price {
  font-weight: 700;
  font-size: 1.5rem;
  margin-left: 2rem;
}

.description {
  margin-left: 2rem;

  overflow: hidden;
  overflow-wrap: anywhere;
  text-overflow: ellipsis;
  width: 100%;

  word-break: break-all;
}

.description p:first-child {
  margin-top: 0px;
}

.goBack {
  margin-bottom: 2rem;
}

@media (max-width: 768px) {
  .twoColumns {
    flex-direction: column;
  }

  .firstColumn:not(.zoom) {
    width: unset;
  }

  .firstColumn.zoom {
    width: unset;
  }

  .mainImage:not(.zoom) {
    width: unset;
    height: unset;
  }

  .mainImage.zoom {
    width: unset;
  }
}

@media (max-width: 462px) {
  .mainImage {
    width: 100%;
  }
  .firstColumn {
    width: 100%;
  }
}
