.goBack {
  display: flex;
  align-items: center;
  margin-top: 10px;
  margin-bottom: 10px;
  color: var(--primary-color);
}

.goBack:hover {
  cursor: pointer;
  text-decoration: underline;
}

.goBack > span {
  margin-left: 10px;
  font-weight: 600;
}
