.container {
  width: 100%;
  display: flex;
  flex-direction: row;
}

.left {
  display: flex;
  flex-direction: column;
  padding: 2.8125rem 1.25rem 0.9375rem 0.9375rem;
  height: calc(100vh - 4.325rem);
  width: 18.125rem;
  position: fixed;
  justify-content: space-between;
  background-color: var(--primary-color);
  transition: width 0.5s ease;
  white-space: nowrap;
}

.left:not(.open) {
  width: 2.8125rem;
  transition: width 0.5s ease;
}

.left .action.selectedRow {
  color: white;
  font-weight: 600;
}

.left .action:not(.selectedRow) {
  color: #afc3df;
}

.left .action {
  margin-top: 0.1875rem;
  margin-bottom: 0.1875rem;
  cursor: pointer;
  position: relative;
}

.left .action:hover {
  text-decoration: none;
  font-weight: 600;
}

.left .actionTitle {
  color: white;
  font-weight: 600;
  margin-bottom: 0.625rem;
  text-transform: uppercase;
  transition: opacity 0.6s ease;
}

.left .actionTitle:first-of-type {
  margin-top: 0;
}

.left:not(.open) .selectedRow {
  border-right: solid 0.3125rem var(--background);
  margin-right: -1.25rem;
}

.left:not(.open) .actionText {
  pointer-events: none;
}

.open .selectedRow::after {
  display: inline-block;
  height: 0;
  width: 0;
  content: "";
  border-top: 0.5625rem solid transparent;
  border-right: 0.9375rem solid var(--background);
  border-bottom: 0.5625rem solid transparent;
  font-weight: 600;
  position: absolute;
  right: -1.25rem;
  /* margin-top: .125rem; */
}

.openCloseIcon {
  position: absolute;
  top: 0px;
  right: 0px;
  font-size: 2rem;
  cursor: pointer;
  width: 2rem;
  height: 2rem;
}

.left:not(.open) .action {
  white-space: nowrap;
}

.left:not(.open) > .actionTitle {
  opacity: 0;
}

.actionText {
  opacity: 1;
  transition: opacity 0.6s ease;
  /* position: absolute; */
  /* left: 0; */
}

.left:not(.open) .actionText {
  opacity: 0;
  transition: opacity 0.6s ease;
}

.iconContainer {
  width: 1.5rem;
  display: inline-block;
}

.right {
  margin-left: 18.125rem;
  padding: 0.625rem;
  flex: 1;
  max-width: calc(100vw - 18.125rem);
  transition: all 0.5s;
}

.right:not(.open) {
  margin-left: 2.8125rem;
  max-width: calc(100vw - 2.8125rem);
}
