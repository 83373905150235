.container {
  display: flex;
  margin: auto;
  max-width: 93.75rem;
}

.card {
  margin: 2rem;
  flex: 1;
}

.label {
  font-weight: 600;
  font-size: 0.875rem;
}

.filePreview {
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: contain;
  width: 200px;
  height: 200px;
  position: relative;
}

.videoPreview {
  height: 200px;
  width: 200px;
  position: relative;
}

.videoPreview video {
  height: 200px;
  width: 200px;
}

.filePreviewContainer {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.filePreviewContainer.empty {
  margin-top: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 5rem;
  background-color: var(--background);
  color: var(--secondary-text);
}

.filePreviewContainer.empty::after {
  content: "Prévisualisation des fichiers";
}

.trashDeleteMedia {
  position: absolute;
  color: var(--red);
  top: 1rem;
  right: 1rem;
  cursor: pointer;
}
