.row {
  margin-top: .625rem
}

.row:not(:last-child) {
  padding-bottom: .625rem;
  border-bottom: 1px solid var(--dot-border);
}

.content {
  display: none;
}

.row.open > .content {
  display: block;
}

.row.open .title {
  font-weight: 600;
}

.title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 5px;
  padding-bottom: 5px;
  font-weight: 500;
  cursor: pointer;
}

.title:hover {
  font-weight: 600;
}

.title p {
  margin: 0;
}
