.menu {
  padding: .625rem;
  background-color: var(--border-color);
  width: 15.625rem;
  border-radius: .3125rem;
}

.titleContainer p {
  color: var(--primary-color);
  font-weight: 600;
  margin: 0;
}

.titleContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.eraser {
  width: 1rem;
  height: 1rem;
  transition-duration: 150ms;
}

.eraser:hover {
  color: var(--blue);
  cursor: pointer;
}