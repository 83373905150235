.modalCategoryHeader {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.inputCategory {
  width: 15.625rem !important;
  margin-bottom: 0px !important;
}
.modalCategoryHeader p {
  margin-top: 0px;
  margin-left: 1.875rem;
}

.table tbody tr td {
  padding-top: 2px;
  padding-bottom: 2px;
  /* border-top: 2px solid var(--border-color); */
  box-sizing: content-box;
}

.cardHeader {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 1.25rem;
}

.cardHeaderInput {
  margin-bottom: 0 !important;
  width: 15.625rem !important;
}

.productContainerEmpty {
  background-color: var(--background);
  color: var(--secondary-text);
  width: 100%;
  height: 15rem;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  cursor: pointer;
}

.emptyIcon {
  width: 5rem;
  height: 5rem;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}

.categoryName {
  font-weight: 500;
  margin-left: 1rem;
}

.categoryDescription {
  overflow: hidden;
  overflow-wrap: anywhere;
  text-overflow: ellipsis;

  /* display: -webkit-box; */
  -webkit-line-clamp: 3;
  line-clamp: 3;
  -webkit-box-orient: vertical;
}

.categoryFirstCol {
  display: flex;
  align-items: center;
}

.productsContainer {
  margin-top: 0.9286rem;
  margin-left: -0.5625rem;

  display: flex;
  flex-direction: column;
  overflow: auto;
}
