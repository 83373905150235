.modalCard {
  margin-top: 1rem;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  flex-direction: column;
}

.gridContainer {
  overflow-y: auto;
  position: absolute;
  padding: 0.9375rem 1.5625rem 1.5625rem;
  top: 4.5rem;
  right: 0;
  bottom: 0;
  left: 0;
}

.galeryImg {
  width: 122px;
  height: 122px;
}

.galeryContainer {
  display: flex;
  flex-direction: column;
  position: relative;
  cursor: pointer;
  width: 122px;
  border-radius: 5px;

  --shadow: rgba(0, 0, 0, 0.04);
  box-shadow: 0px 0px 4px 2px var(--shadow), 0px 0px 4px -2px var(--shadow);
}

.galeryContainer:hover {
  --shadow: rgba(0, 0, 0, 0.125);
  box-shadow: 0px 0px 4px 4px var(--shadow), 0px 0px 4px -4px var(--shadow);
}

.selected {
  box-sizing: border-box;
  border: 5px solid var(--secondary-color);
}

.capturesContainer {
  padding: 5px;
  margin-top: 1rem;

  --grid-layout-gap: 20px;
  --grid-column-count: 20;
  --grid-item--min-width: 122px;
  --gap-count: calc(var(--grid-column-count) - 1);
  --total-gap-width: calc(var(--gap-count) * var(--grid-layout-gap));
  --grid-item--max-width: calc((100% - var(--total-gap-width)) / var(--grid-column-count));

  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(max(var(--grid-item--min-width), var(--grid-item--max-width)), 1fr));
  grid-gap: var(--grid-layout-gap);
}

.capturesDate {
  color: var(--primary-color);
  font-weight: 500;
}

.miscContainer {
  display: flex;
  justify-content: space-between;
  flex-direction: row-reverse;
}

.filtersContainer {
  display: flex;
  align-items: baseline;
}

.filtersLabel {
  font-weight: 500;
}

.filtersInput:first-of-type {
  margin-right: 1rem;
  margin-left: 1rem;
}

.filtersSubmit {
  margin-left: 1rem;
  height: 100%;
}

.separator {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.typeIcon {
  /* font-size: 1rem; */
  width: auto;
  height: 1rem;
  color: var(--gray-lighter);
  position: absolute;
  bottom: 0.35rem;
  right: 0.4rem;
  transform-origin: bottom right;
}

.typeIcon:hover {
  animation: zoom 0.5s 1 alternate ease-out forwards;
}

@keyframes zoom {
  100% {
    transform: scale(3);
    /* bottom: 0.6rem;
    right: 0.65rem; */
  }
}
