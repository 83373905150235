.label {
  font-weight: 600;
  font-size: .875rem;
  margin-bottom: 0;
  margin-top: 0;
}

.select {
  display: flex;
  justify-content: space-between;
  background-color: #f5f5f5;
  padding: .4375rem;
  border-radius: .25rem;
  position: relative;
  height: 2.5rem;
  min-width: 9.375rem;
  align-items: center;
  cursor: pointer;
  margin-bottom: .625rem;
}

.select.invalid {
  padding: .375rem;
}

.select svg {
  margin-left: .625rem;
}

.select p {
  font-size: 1rem;
  margin: 0;
}

.dropDown {
  position: absolute;
  display: none;
  top: 2.8125rem;
  left: 0;
  min-width: 100%;
  background-color: var(--border-color);
  border: 1px solid var(--background);
  filter: var(--drop-shadow);
  border-radius: .3125rem;
  max-height: 9.375rem;
  overflow-x: auto;
  z-index: 100;
}

.dropDown::-webkit-scrollbar {
  width: .1875rem;
}

.dropDown::-webkit-scrollbar-thumb {
  background: var(--primary-color);
  border-radius: .625rem;
}

.dropDown::-webkit-scrollbar-track {
  background: transparent;
}

.dropDown.opened {
  display: block;
}

.dropDown p {
  margin: 0;
  padding: .3125rem;
  transition: background-color 0.2s;
}

.dropDown p:first-child {
  border-radius: .3125rem .3125rem 0 0;
}

.dropDown p:last-child {
  border-radius: 0 0 .3125rem .3125rem;
}

.dropDown p:hover {
  background-color: #b2babb;
}

.invalid {
  border: .0625rem solid var(--red);
}


.caretDown {
  width: 1rem;
  height: 1rem;
  transition-duration: 250ms;
}

.reverseCaretDown {
  transform: rotateX(180deg);
}


.backgroundWhite {
  background-color: white;
}