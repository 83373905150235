.container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  justify-content: space-evenly;
  margin-top: 1rem;
}

.bigContainer {
  display: flex;
  margin: 2rem;
  align-items: center;
  flex-direction: column;
}

.card {
  max-width: 93.75rem;
}

.homeContainer {
  height: 21.4286rem;
  position: relative;
  max-width: 32%;
  flex-basis: 32%;
  border: 1px solid var(--background);
  padding: 0.7143rem 1.0714rem 1.0714rem 1.0714rem;
  margin-bottom: 1.1429rem;
  border-radius: 5px;
  transition-duration: 100ms;
  background-color: white !important;
  display: flex;
  flex-direction: column;
}

.homeContainer:hover {
  cursor: pointer;
  box-shadow: 0px 10px 15px -3px rgba(0, 0, 0, 0.1);
}

.homeContainerTitle {
  color: var(--primary-color);
  font-weight: 600;
  font-size: 1.1429rem;
  line-height: 1.4286rem;
}

.homeContainerChildren {
  height: 100%;
  display: flex;
  flex-direction: column;
  /* justify-content: space-between; */
  /* background-color: pink; */
}

.video {
  position: absolute;
  transform-origin: center;
  left: auto;
  height: 100%;
  aspect-ratio: 1 / 1;
}

.itemImage {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background-repeat: no-repeat;
  background-size: contain;
}

.itemList {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}

.listItem {
  display: flex;
  align-items: center;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.listItem.clickable:hover {
  cursor: pointer;
  background-color: white;
  filter: var(--medium-drop-shadow);
  border-radius: 1rem;
}

.mobileImage {
  margin: 2rem;
}

.itemContainer {
  flex: 1;
  position: relative;
  margin-top: 1rem;
  display: flex;
  justify-content: center;
}

.faIcon {
  color: var(--primary-color);
  margin-right: 1rem;
}

.linkableContainer {
  width: 100%;
  height: 100%;
  background-color: var(--background);

  position: relative;
}

.linkableIcon {
  width: 20% !important;
  height: 20% !important;

  position: absolute;
  margin: auto;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}

.linkableLogo {
  width: 30%;

  position: absolute;
  margin-top: auto;
  margin-bottom: auto;
  top: 0;
  bottom: 0;
}

.mediaLogo {
  right: 10%;
}

.catalogueLogo {
  left: 10%;
}
