.public {
  filter: none;
}

.catalogInfo {
  display: flex;
  /* justify-content: center; */
  align-items: center;
}

.catalogInfo > * {
  margin: 0 0.3571rem;
}

.catalogInfo > *:last-child {
  margin-right: 0px;
}

.icon {
  cursor: pointer;
  margin-left: 0.5rem;
}

.catalogBanner {
  /* height: 300px; */
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--input-icon);
}

.catalogBanner.empty {
  background-color: #f2f4f9;
  padding-bottom: 23.64%;
}

.catalogBannerVideo {
  padding-bottom: 23.64%;
  width: 100%;
}

.bannerImage {
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  width: 100%;
  padding-bottom: 23.64%;
}

.catalogLogo {
  height: 148px;
  width: 148px;
  position: absolute;
  left: 28px;
  bottom: -19px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #f2f4f9;
  border-radius: 0.3571rem;
  background-color: white;
}

.catalogLogo.empty {
  background-color: white;
}

.cameraLogoContainer {
  width: 100%;
  padding-bottom: 100%;
  color: var(--input-icon);
}

.cameraLogo {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 2.2857rem;
}

.logoImage {
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  width: 100%;
  padding-bottom: 100%;
}

.catalogueContent {
  display: flex;
  margin-top: 3.5rem;
}

.catalogName {
  font-size: 1.125rem;
  font-weight: 700;
  color: var(--primary-color);
}

.catalogNameContainer {
  margin-left: 28px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 0.5rem;
}

.catalogInfosIcons {
  display: flex;
  align-items: center;
}

.productsContainerHeader {
  font-weight: 700;
  margin: 1rem 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.productsContainer {
  display: flex;
  flex-wrap: wrap;
  margin-top: 0.9286rem;
  /* margin-left: -0.5625rem; */
}

.contactContainer {
  display: flex;
  justify-content: center;
  width: 100%;
}

.contactButton {
  margin: 1rem;
}
