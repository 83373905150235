.container {
  display: flex;
  margin: auto;
  max-width: 93.75rem;
}

.containerInModal {
  display: flex;
  flex-direction: column;
}

.card {
  margin: 2rem;
  flex: 1;
}
