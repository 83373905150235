.productCover {
  width: 100%;
  height: 198px;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: contain;
}

.productCoverMultiple {
  width: 100%;
  height: 198px;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: contain;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  position: relative;
}

.productCoverMultiple video {
  width: 100%;
  height: 100%;
  background-color: black;
}

.carretContainer {
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-top: 30px;
  padding-bottom: 30px;
  position: absolute;
  top: 0;
  opacity: 0;
  width: 50%;
  height: 80%;
  z-index: 0;
}

.carretContainer.left {
  left: 0;
  border-radius: 0 4px 4px 0;
}

.carretContainer.right {
  right: 0;
  border-radius: 4px 0 0 4px;
}

.carretContainer:hover {
  cursor: pointer;
}

.dotContainer {
  display: flex;
  position: absolute;
  bottom: -30px;
  left: 50%;
  transform: translateX(-50%);
  justify-content: center;
  background-color: rgba(0, 0, 0, 0);
  border-radius: 0 0 4px 4px;
}

.dot {
  height: 15px;
  margin: 5px;
  z-index: 10;
  color: white;

  user-select: none;
}

.current {
  color: #f4d03f;
}

.dot:hover {
  cursor: pointer;
}

.faCaretLeft {
  font-size: 1.4286rem !important;
  width: 1.4286rem !important;
  color: white;
}

.faCaretRight {
  font-size: 1.4286rem !important;
  width: 1.4286rem !important;
  color: white;
}

.animationRight {
  z-index: 11;
  pointer-events: none;

  animation-name: switchRight;
  animation-iteration-count: 1;
  animation-duration: 0.25s;
}

.animationLeft {
  z-index: 11;
  pointer-events: none;

  animation-name: switchLeft;
  animation-iteration-count: 1;
  animation-duration: 0.25s;
}

.videoIcon {
  transform-origin: center;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;

  width: 3rem;
  font-size: 3rem;

  color: var(--white);

  stroke: black;
  stroke-width: 10px;
}

@keyframes switchRight {
  100% {
    transform: translateX(1.7857rem);
  }
}

@keyframes switchLeft {
  100% {
    transform: translateX(-1.7857rem);
  }
}
