.card {
  padding: 0.9375rem 1.5625rem 1.5625rem;
  background: var(--white);
  border-radius: 0.625rem;
  filter: var(--drop-shadow);

  flex: 1;
  position: absolute;
  top: 2rem;
  bottom: 2rem;
  left: 2rem;
  right: 2rem;
}

.cardHeader {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 1.25rem;
}

.image {
  width: 35.7143rem;
  height: 35.7143rem;
  margin: 1rem;
}

.title {
  font-weight: 700;
  font-size: 2rem;
}

.separator {
  height: 2px;
  width: 100%;
  background-color: var(--border-color);
  margin-top: 0.75rem;
  margin-bottom: 0.75rem;
}

.titleContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
