.listCategory {
  margin-top: 0.7143rem;
  margin-bottom: 0.7143rem;
}
.listSubCategoryContainer {
  margin-left: 2rem;
}
.listSubCategory {
  margin-top: 0.7143rem;
  margin-bottom: 0.7143rem;
}

.listSelected {
  font-weight: 700;
  border-right: 3px solid black;
}

.listCategory span:hover,
.listSubCategory:hover {
  cursor: pointer;
  font-weight: 700;
}
/* 
.gridContainer {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.gridCard {
    position: relative;
    flex-basis: 32%;
    border: 3px solid #F2F4F9;
    padding: 10px 15px 15px 15px;
    margin-bottom: 16px;
    border-radius: 5px;
    transition-duration: 100ms;
  }
  
  .gridCard:hover {
    box-shadow: 0px 10px 15px -3px rgba(0,0,0,0.1);
  } */

.productsContainer {
  display: flex;
  flex-wrap: wrap;
  margin-top: 0.9286rem;
  margin-left: -0.5625rem;
  /* justify-content: space-between; */
}

.productContainer {
  width: 31%;
  margin: 0.5625rem;
  position: relative;
  border: 1px solid var(--border-color);
  border-radius: 0.3125rem;
  min-width: 14rem;
  cursor: pointer;
}

.productContainer:hover {
  box-shadow: 0px 10px 15px -3px rgba(0, 0, 0, 0.1);
}

.productImage {
  width: 100%;
  border-top-left-radius: 0.3125rem;
  border-top-right-radius: 0.3125rem;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  cursor: pointer;
  position: relative;
}

.productImage::before {
  content: "";
  display: block;
  padding-top: 100%; /* initial ratio of 1:1*/
}

.productContent {
  background-color: white;
  width: 100%;
  padding: 0.5rem 0.9375rem 0.8125rem 0.9375rem;
  border-bottom-left-radius: 0.3125rem;
  border-bottom-right-radius: 0.3125rem;
}

.productName {
  font-weight: 700;
}

.priceContainer > *:nth-child(2) {
  margin-left: 0.7143rem;
}

.productDescription {
  overflow: hidden;
  overflow-wrap: anywhere;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  line-clamp: 3;
  -webkit-box-orient: vertical;
  height: 3.75rem;
  margin-top: 0.5rem;
}

.productDescription p {
  margin: 0;
}

.productDescription h1,
h2,
h3 {
  margin: 0;
}

.productMoreInfo {
  margin-top: 1.5rem;
  bottom: 1rem;
  left: 1rem;
  border-radius: 0.3571rem;
  padding: 0.3571rem 0.7143rem;
}

.productsContainerHeader {
  font-weight: 700;
  margin: 1rem 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.noProducts {
  background-color: #f2f4f9;
  width: 100%;
  height: 150px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #bdbdbd;
}

.catalogBanner {
  /* height: 300px; */
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--input-icon);
}

.catalogBanner.empty {
  background-color: #f2f4f9;
  padding-bottom: 23.64%;
}

.catalogBannerVideo {
  padding-bottom: 23.64%;
  width: 100%;
}

.editMode .catalogBanner {
  cursor: pointer;
}

.editMode .catalogBanner:not(.catalogLogo):hover {
  color: var(--gray-darker);
}

.catalogLogo {
  height: 148px;
  width: 148px;
  position: absolute;
  left: 28px;
  bottom: -19px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #f2f4f9;
  border-radius: 0.3571rem;
  background-color: white;
}

.catalogLogo.empty {
  background-color: white;
}

.editMode .catalogLogo {
  cursor: pointer;
  z-index: 1;
}

.editMode .catalogLogo.empty :hover {
  color: var(--gray-darker);
}

.catalogName {
  font-size: 1.125rem;
  font-weight: 700;
  color: var(--primary-color);
}

.catalogNameContainer {
  margin-left: 28px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 0.5rem;
}

.catalogInfosIcons {
  display: flex;
  align-items: center;
  /* flex-direction: row; */
  /* align-items: center; */
  /* justify-content: space-between; */
}

.nameEdit {
  color: var(--black);
  margin-left: 0.7143rem;
  cursor: pointer;
}

.chooseMainColor {
  font-weight: 600;
  color: var(--blue);
}

.label {
  font-weight: 600;
}

.colorPreview {
  width: 125px;
  height: 165px;
  position: absolute;
}

.filePreview {
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: contain;
  width: 10.4286rem;
  height: 10.4286rem;
  position: relative;
  border-radius: 0.25rem;
  flex: 1;
  z-index: 0;
}

.bannerPreview {
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: contain;
  width: 30.8571rem;
  height: 7.2857rem;
  position: relative;
  border-radius: 0.25rem;
  flex: 1;
  z-index: 0;
}

.filePreview:not(.empty) {
  display: flex;
  align-items: center;
  justify-content: center;
}

.bannerPreview:not(.empty) {
  display: flex;
  align-items: center;
  justify-content: center;
}

.filePreview.empty {
  width: 100%;
  border: none;
}

.bannerPreview.empty {
  width: 100%;
  border: none;
}

.logoImage {
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  width: 100%;
  /* height: 100%; */
  padding-bottom: 100%;
}

.bannerImage {
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  width: 100%;
  padding-bottom: 23.64%;
}

.catalogInfo {
  display: flex;
  /* justify-content: center; */
  align-items: center;
}

.catalogInfo > * {
  margin: 0 0.3571rem;
}

.catalogInfo > *:last-child {
  margin-right: 0px;
}

.icon {
  cursor: pointer;
  margin-left: 0.5rem;
}

.iconDisabled {
  color: var(--unselected-icon);
}

.catalogContactRow {
  display: flex;
  position: relative;
  align-items: center;
  margin-top: 0.75rem;
}

.catalogCheckbox {
  margin-top: 10px;
}

.goBack {
  padding: 0.5625rem 1rem;
  border-radius: 0.25rem;
  color: white;
  margin: "20px 0";
  background-color: var(--blue);
}

.topBar {
  position: fixed;
  display: flex;
  right: 0;
  left: 0;
  margin-left: auto;
  margin-right: auto;
  height: 4rem;
  max-width: 93.75rem;
  justify-content: space-between;
  align-items: center;
  z-index: 2;
  /* background-color: var(--background); */
}

.quitPreview {
  position: -webkit-fixed; /* Safari */
  position: fixed;
  /* top: 2.25rem; */
  left: 50%;
  z-index: 10;
  transform: translate(-50% /*, -50%*/);
}

.topButtons {
  /* position: fixed;
  top: 2.25rem;
  left: 50%;
  z-index: 10;
  transform: translate(-50%, -50%); */
  display: flex;
  justify-content: flex-end;
}

.public {
  filter: none;
}

.cameraBanner {
  /* color: var(--input-icon); */
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 5.1429rem;
}

.cameraLogoContainer {
  width: 100%;
  padding-bottom: 100%;
  color: var(--input-icon);
}

.cameraLogo {
  /* color: var(--input-icon); */
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 2.2857rem;
}

.catalogueContent {
  display: flex;
  margin-top: 3.5rem;
}

.trashLogo {
  color: red;
  position: absolute;
  top: 5px;
  right: 5px;
}

.input {
  z-index: 1 !important;
  position: "relative" !important;
}

.productIcon {
  margin-left: 0.75rem;
  font-size: 1.25rem;
}

.delete {
  color: var(--red);
}

.productIcon:hover {
  border: 1px solid transparent;
}

.settingLabel {
  font-weight: 600;
  font-size: 0.875rem;
  margin-bottom: 0;
  margin-top: 0;
  font-size: 1rem;
}

.settingContact {
  display: flex;
  align-items: center;
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.companyInfos {
  overflow: auto;
  overflow-wrap: anywhere;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 10;
  line-clamp: 10;
  -webkit-box-orient: vertical;
  height: fit-content;
  margin-top: 0.5rem;

  padding-top: 1rem;
  padding-bottom: 2rem;
  padding-right: 5rem;
  padding-left: 5rem;

  max-height: 25rem;
}

.companyInfos ul {
  overflow-y: auto;
}

.scanLabel {
  font-size: 1.1429rem;
  font-weight: 700;
  line-height: 1.4286rem;
  text-align: center;
  width: 100%;
  margin-top: 2rem;
}

.qrCodeContainer {
  height: 16.2857rem;
}

@media (max-width: 768px) {
  .catalogLogo {
    top: 50%;
    left: unset;
    bottom: unset;
    height: unset;
    width: 20%;
    /* width: 30%; */
    /* height: 30%; */
  }

  .catalogueContent {
    margin-top: 5rem;
    flex-direction: column;
  }
}

@media (max-width: 750px) {
  .productContainer {
    width: 44%;
  }
}

@media (max-width: 498px) {
  .productContainer {
    width: 100%;
  }

  .qrCodeContainer {
    height: 10rem;
  }

  .companyInfos {
    padding-top: 0.5rem;
    padding-bottom: 2rem;
    padding-right: 1rem;
    padding-left: 1rem;
  }

  .scanLabel {
    font-size: 1rem;
  }
}
